





















































































.titlePStyle {
  margin-bottom: 0.2rem;
  margin-top: 0.3rem;
  padding-top: 0.1rem;
  transform: rotate(180deg);
}
.headIcon {
	width: 1.5rem;
	margin: 0 auto;
	padding-top: 1.28rem;
	img {
		width: 100%;
		height: 100%;
	}
}
.headtxt {
	width: 4.95rem;
	margin: 0 auto;
	margin-top: 0.69rem;
	img {
		width: 100%;
		height: 100%;
	}
}
.headtxt1 {
	width: 4.06rem;
	margin: 0 auto;
	margin-top: 0.44rem;
	img {
		width: 100%;
		height: 100%;
	}
}
.download {
  // background-image: url("../../assets/imgs/share/backGroundImg2.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top center;
  height: 100%;
  width: 100%;
  color: white;
  text-align: center;

  .shareFooter {
	  // margin-top: 302px;
    
    position: absolute;
    bottom: 1.45rem;
    left: 0;
    width: 100%;

    div {
      width: 50%;
      float: left;

      a,span {
        display: block;
        margin: auto;
        width: 2.54rem;
        border-radius: 8px;
        height: 0.9rem;
        line-height: 0.9rem;
        font-size: 0.3rem;
        cursor: pointer;
      }

      .iosButton {
        color: #000;
		    font-weight: bold;
        background: #fff;
		    font-size: 0.32rem;
      }

      .androidButton {
        color: #fff;
        background: #5a54ea;
      }

      img {
        width: 0.45rem;
        vertical-align: -0.1rem;
      }
    }
  }
}
.left {
	position: absolute;
	left: .3rem;
	top: 0;
	.icon {
		width: .6rem;
		height: .88rem;
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			width: .4rem;
			height: .4rem;	
		}
	}
}
.icon_arrL {
    border-top: .04rem solid #fff;
    border-left: .04rem solid #fff;
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;