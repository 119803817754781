.titlePStyle {
  margin-bottom: 0.2rem;
  margin-top: 0.3rem;
  padding-top: 0.1rem;
  transform: rotate(180deg);
}
.headIcon {
  width: 1.5rem;
  margin: 0 auto;
  padding-top: 1.28rem;
}
.headIcon img {
  width: 100%;
  height: 100%;
}
.headtxt {
  width: 4.95rem;
  margin: 0 auto;
  margin-top: 0.69rem;
}
.headtxt img {
  width: 100%;
  height: 100%;
}
.headtxt1 {
  width: 4.06rem;
  margin: 0 auto;
  margin-top: 0.44rem;
}
.headtxt1 img {
  width: 100%;
  height: 100%;
}
.download {
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top center;
  height: 100%;
  width: 100%;
  color: white;
  text-align: center;
}
.download .shareFooter {
  position: absolute;
  bottom: 1.45rem;
  left: 0;
  width: 100%;
}
.download .shareFooter div {
  width: 50%;
  float: left;
}
.download .shareFooter div a,
.download .shareFooter div span {
  display: block;
  margin: auto;
  width: 2.54rem;
  border-radius: 8px;
  height: 0.9rem;
  line-height: 0.9rem;
  font-size: 0.3rem;
  cursor: pointer;
}
.download .shareFooter div .iosButton {
  color: #000;
  font-weight: bold;
  background: #fff;
  font-size: 0.32rem;
}
.download .shareFooter div .androidButton {
  color: #fff;
  background: #5a54ea;
}
.download .shareFooter div img {
  width: 0.45rem;
  vertical-align: -0.1rem;
}
.left {
  position: absolute;
  left: 0.3rem;
  top: 0;
}
.left .icon {
  width: 0.6rem;
  height: 0.88rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.left .icon img {
  width: 0.4rem;
  height: 0.4rem;
}
.icon_arrL {
  border-top: 0.04rem solid #fff;
  border-left: 0.04rem solid #fff;
}
